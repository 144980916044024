import { Component, OnInit, Input } from "@angular/core";
import { SelectItem, MessageService } from "primeng/api";
import { Router } from "@angular/router";
import { SharedService } from "src/app/services/shared/shared.service";
import { ApiService } from "../../services/token/api.service";
import { Subject, takeUntil } from "rxjs";
import { ApiService as projectService } from "../../services/project/api.service";
import {
  SocialUser,
  SocialAuthService,
  GoogleLoginProvider,
} from "@abacritt/angularx-social-login";
import { MenuItem } from "primeng/api/menuitem";
import { DynamicDialogConfig, DialogService } from "primeng/dynamicdialog";
// import { version } from "../../../../package.json";
import * as CryptoJS from "crypto-js";
import jwt_decode from "jwt-decode";
import { CookieService } from "ngx-cookie";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import * as ipaddr from "ipaddr.js";
import { FlyerManagementServiceService } from "src/app/services/flyer-management-service.service";

export interface Car {
  deal_ID: string;
  kick_Off_Date: string;
  account_lead: string;
  solution_architect: string;
  project_lead: string;
  project_name: string;
  project_start_date: string;
  account_name: string;
}
@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
  providers: [MessageService, DialogService],
})
export class HomeComponent implements OnInit {
  ipAddress: any;
  myStyle: Object = {};
  myParams: object = {};
  // id = "home";
  width: number = 100;
  height: number = 100;
  decoded: any;
  client_id = environment.sign_in_provider;
  @Input() changeDesignation: Subject<any>;
  public user: SocialUser;
  private loggedIn: boolean;
  designation: string;
  team: any;
  version = "6.4";
  isNotLogin: boolean = true;
  isLogin: boolean = false;
  designationDropDown = [];
  // loading: boolean = false;
  products = [
    {
      id: "1",
      image: "1st.png",
    },
    {
      id: "2",
      image: "2nd.png",
    },
    {
      id: "3",
      image: "3rd.png",
    },
    // {
    //   id: "4",
    //   image: "4th.jpeg",
    // },
  ];
  designationObject = {
    "super-super-admin": "Super Super Admin",
    "super-admin": "Super Admin",
    admin: "Admin",
    al: "GTM Lead",
    sa: "Solution Architect",
    user: "User",
  };

  private unsubscribe: Subject<void> = new Subject();

  designationSelected: any;
  items: MenuItem[];
  passwordemail: string;
  textToConvertEmail: string;
  constructor(
    private authService: SocialAuthService,
    private _api: projectService,
    private _router: Router,
    private _toaster: MessageService,
    private _tokenService: ApiService,
    private _shared: SharedService,
    private _cookieService: CookieService,
    private http: HttpClient
  ) {
    this.passwordemail = "email";
  }

  ngOnInit() {
    // this.validateIp();
    this.authService.authState
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((user) => {
        this.user = user;
        this.loggedIn = user != null;
        if (this.loggedIn) {
          localStorage.setItem("idToken", this.user["idToken"]);
          localStorage.setItem("name", this.user["name"]);
          localStorage.setItem("profile", this.user["photoUrl"]);
          // this._tokenService.istokenExpired = false;
          this._tokenService
            .getAuthorizedId(user["idToken"])
            .subscribe((tokenData) => {
              this._cookieService.remove("access_token");
              localStorage.setItem(
                "auth_token",
                tokenData["user_list"]["token"]
              );
              // this._cookieService.put("signin_token", this.user["authToken"]);
              // if (this._tokenService.istokenExpired) {
              this._tokenService.istokenExpired = false;
              // }
              // this.loggedIn = tokenData != null;

              //Route based on Designation
              this.decoded = jwt_decode(localStorage.getItem("auth_token"));
              this.decoded["designation"].forEach((designation) => {
                this.designationDropDown.push({
                  name: this.designationObject[designation],
                  value: designation,
                });
              });
              // this.designation = this.designationDropDown[0].name;
              // this.designationSelected = {
              //   name: this.designationObject[this.decoded["designation"][0]],
              //   value: this.decoded["designation"][0],
              // };

              this._shared.setDesignation(this.designationDropDown[0].value);
              // this._shared.designationSubject.next(
              //   this.designationDropDown[0].value
              // );
              this.userLogin();
              this._shared.setSessionTime(this.decoded.expiry);

              // this.team = this.decoded["team"];
              // let routeURL = localStorage.getItem("routeURL");

              // if (!routeURL) {
              //   this._router.navigate(["/list-projects"]);

              // } else {
              //   localStorage.setItem("routeURL", "");
              //   this._router.navigate([`/${routeURL}`]);
              // }
              if (!this._tokenService.istokenExpired) this.checkAndNavigate();
            });
          // this.loading = false;
        } else {
          if (user) {
            setTimeout(() => {
              this._toaster.add({
                key: "common",
                severity: "warn",
                summary: "Session Expired!",
                detail: "Kindly re-login",
                life: 300000,
              });
            }, 1000);
            this._tokenService.istokenExpired = true;
            localStorage.clear();
            this._router.navigate(["/login"]);
            this._cookieService.removeAll();
            this.authService.signOut();
          }
        }
      });

    // if (this._tokenService.istokenExpired) {
    //   setTimeout(() => {
    //     this._toaster.add({
    //         key:'common',
    //       severity: "warn",
    //       summary: "Session Expired!",
    //       detail: "Kindly re-login",
    //       life: 300000,
    //     });
    //   }, 1000);
    //   this._shared.setSessionTime(null);
    // }

    this.designation = this._shared.getDesignation();
    if (this.designation) {
      this.homeRoute();
    }

    if (
      localStorage.getItem("routeURL") &&
      localStorage.getItem("routeURL") !== ""
    ) {
      setTimeout(() => {
        this._toaster.add({
          key: "common",
          severity: "warn",
          summary: "Login Required!",
          detail: "Please login first",
          life: 6000,
        });
      }, 1000);
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  // checkIpAddress(ip: any): boolean {
  //   const targetIp = ipaddr.parse(ip);
  //   for (const range of IP_WHITELIST) {
  //     const [subnet, subnetBitsString] = range.split("/");
  //     const subnetBits = parseInt(subnetBitsString, 10);

  //     // Convert subnet to CIDR format
  //     const cidrRange = `${subnet}/${subnetBits}`;

  //     const subnetIp = ipaddr.parseCIDR(cidrRange)[0];

  //     if (targetIp.match(ipaddr.parseCIDR(range))) {
  //       console.log("match found :", ipaddr.parseCIDR(range));
  //       return true; // IP address is in the whitelist
  //     }
  //   }
  //   return false; // IP address is not in the whitelist
  // }

  // validateIp() {
  //   this.http.get<{ ip: string }>("https://jsonip.com").subscribe((data) => {
  //     this.ipAddress = data.ip;
  //     console.log("IP = ", this.ipAddress);
  //     if (this.checkIpAddress(this.ipAddress)) {
  //       console.log("ALlow login");
  //     } else {
  //       console.log("Block login");
  //     }
  //   });
  // }

  homeRoute() {
    // if (
    //   this.designation == "al" ||
    //   this.designation == "sa" ||
    //   this.designation == "user"
    // )
    //   this._router.navigate(["/create-project"]);
    // else
    //this._router.navigate(["/list-projects"]);
    this.designation == "rfp_admin"
      ? this._router.navigate(["/rfp-management"])
      : this._router.navigate(["/list-projects"]);
  }

  // sign in with google
  // signInWithGoogle(): void {
  //   this.loading = true;
  //   this.authService
  //     .signIn(GoogleLoginProvider.PROVIDER_ID)
  //     .then((initialUser) => {
  //       const options = {
  //         headers: {
  //           "Content-Type": "text/plain",
  //           "X-Requested-With": "XMLHttpRequest",
  //         },
  //         payload: initialUser["idToken"],
  //       };
  //       // this._tokenService.getAuthorized(initialUser['idToken'])
  //       //   .subscribe((tokenData) => {
  //       //     localStorage.setItem('Pemissions', tokenData['data']['permissions'])
  //       //   });
  //       this._tokenService.isUserAllowed = true;
  //       this.user = !this.user ? initialUser : this.user;
  //       localStorage.setItem("name", this.user["name"]);
  //       localStorage.setItem("profile", this.user["photoUrl"]);
  //       //API call to get user email and token and store it in dataService
  //       this._tokenService
  //         .getAuthorizedId(initialUser["idToken"])
  //         .subscribe((tokenData) => {
  //           localStorage.setItem("auth_token", tokenData["user_list"]["token"]);
  //           // this._cookieService.put("auth_token", tokenData['data']["token"]);
  //           this._cookieService.put("signin_token", this.user["authToken"]);

  //           // localStorage.setItem("email", tokenData["user_list"]["email"]);
  //           if (this._tokenService.istokenExpired) {
  //             this._tokenService.istokenExpired = false;
  //           }
  //           this.loggedIn = tokenData != null;

  //           //Route based on Designation
  //           this.decoded = jwt_decode(localStorage.getItem("auth_token"));
  //           this.decoded["designation"].forEach((designation) => {
  //             this.designationDropDown.push({
  //               name: this.designationObject[designation],
  //               value: designation,
  //             });
  //           });
  //           this.designation = this.designationDropDown[0].name;
  //           this.designationSelected = {
  //             name: this.designationObject[this.decoded["designation"][0]],
  //             value: this.decoded["designation"][0],
  //           };

  //           this._shared.setDesignation(this.designationDropDown[0].value);
  //           this._shared.designationSubject.next(
  //             this.designationDropDown[0].value
  //           );
  //           this.userLogin();
  //           this._api.getServerTime().subscribe((serverTime) => {
  //             this._shared.setSessionTime(serverTime["data"]);
  //           });
  //           // this._tokenService
  //           //   .getTeams(tokenData["email"])
  //           //   .subscribe((response) => {
  //           //     if (response["teams"]) {
  //           //       this._shared.setTeam(response["teams"]);
  //           //       this.team = response["teams"];
  //           //     }
  //           //   });
  //           // this._shared.setTeam(this.decoded['team']);
  //           this.team = this.decoded["team"];
  //           let routeURL = localStorage.getItem("routeURL");

  //           if (!routeURL) {
  //             this._router.navigate(["/list-projects"]);
  //             //When redirection from direct page
  //             // if (
  //             //   this.designationDropDown[0].value == "super-admin" ||
  //             //   this.designationDropDown[0].value == "super-super-admin"
  //             // ) {
  //             // } else {
  //             //   this._router.navigate(["/workq"]);
  //             // }
  //           } else {
  //             //When redirection from mail-thread and then from login
  //             localStorage.setItem("routeURL", "");
  //             this._router.navigate([`/${routeURL}`]);
  //           }
  //           //   },
  //           //   (err) => {
  //           //     if (
  //           //       err.status === 404 &&
  //           //       err["error"]["status"] === "user not found"
  //           //     ) {
  //           //       // redirect to the logout route with Session Expire Error Msg
  //           //       this._router.navigateByUrl("/usernotfound");
  //           //     }
  //           //   }
  //           // );

  //           // storing empId in service
  //           // this._router.navigate(['/home']);
  //         });
  //       this.loading = false;
  //     })
  //     .catch((err) => {
  //       this.loading = false;
  //       console.error(err);
  //     });
  //   this._shared.setProjectName("");
  //   this._shared.setDealId("");
  // }

  userLogin() {
    if (!this.loggedIn) {
      this.isNotLogin = true;
      this.isLogin = false;
    } else {
      this.isNotLogin = false;
      this.isLogin = true;
    }
  }
  async getDealIdsByDesignation(des, email_id) {
    return new Promise((resolve, reject) => {
      this._api.getProjectListData(des, email_id).subscribe(async (res) => {
        let dealIds = res["deal_id"];
        if (dealIds.length > 0) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  }

  async checkAndNavigate() {
    let designationsDecoded = jwt_decode(localStorage.getItem("auth_token"));
    let email_id = designationsDecoded["email_id"];
    let designationsToCheck = designationsDecoded["designation"];
    let found = false;
    let currentDesignation = this._cookieService.get("DG");
    let changeDesignationTo = currentDesignation;

    // Old logic to select designation

    // for (let i = 0; i < designationsToCheck.length; i++) {
    //   let elem = designationsToCheck[i];
    //   if (elem == "user" || elem == "sa") {
    //     let res = await this.getDealIdsByDesignation(elem, email_id);
    //     if (res) {
    //       found = true;
    //       changeDesignationTo = elem;
    //       break;
    //     }
    //   }
    // }
    // if (found) {
    //   this._shared.setDesignation(changeDesignationTo);
    //   this.designationSelected = {
    //     name: this.designationObject[changeDesignationTo],
    //     value: changeDesignationTo,
    //   };
    //   this._shared.changeDesignationRedirect.next(changeDesignationTo);
    //   this._router.navigate(["/list-projects"]);
    // } else {
    //   if (designationsToCheck.length > 0) {
    //     let changeTo = "";

    //     if (designationsToCheck.includes("admin")) {
    //       changeTo = "admin";
    //     } else if (designationsToCheck.includes("super-admin")) {
    //       changeTo = "super-admin";
    //     } else if (designationsToCheck.includes("super-super-admin")) {
    //       changeTo = "super-super-admin";
    //     } else if (designationsToCheck.includes("marketing")) {
    //       changeTo = "marketing";
    //     } else if (designationsToCheck.includes("auditor")) {
    //       changeTo = "auditor";
    //     }
    //     if (changeTo != "") {
    //       this.designationSelected = {
    //         name: this.designationObject[changeTo],
    //         value: changeTo,
    //       };
    //       this.team = designationsDecoded["team"];
    //       this._shared.changeDesignationRedirect.next(changeTo);
    //       this._router.navigate(["/list-projects"]);
    //     } else {
    //       if (
    //         designationsToCheck.includes("sa") ||
    //         designationsToCheck.includes("user")
    //       ) {
    //         changeTo = designationsToCheck[0];
    //         this.designationSelected = {
    //           name: this.designationObject[changeTo],
    //           value: changeTo,
    //         };
    //         this._router.navigate(["/help"]);
    //       } else {
    //         this.designationSelected = {
    //           name: this.designationObject[currentDesignation],
    //           value: currentDesignation,
    //         };
    //         this._router.navigate(["/list-projects"]);
    //       }
    //     }
    //   }
    // }

    // new logic to select designation

    if (designationsToCheck.length > 0) {
      let changeTo = "";

      if (designationsToCheck.includes("admin")) {
        changeTo = "admin";
      } else if (designationsToCheck.includes("super-admin")) {
        changeTo = "super-admin";
      } else if (designationsToCheck.includes("super-super-admin")) {
        changeTo = "super-super-admin";
      } else if (designationsToCheck.includes("user")) {
        changeTo = "user";
      } else if (designationsToCheck.includes("sa")) {
        changeTo = "sa";
      } else if (designationsToCheck.includes("marketing")) {
        changeTo = "marketing";
      } else if (designationsToCheck.includes("auditor")) {
        changeTo = "auditor";
      }
      if (changeTo != "") {
        this.designationSelected = {
          name: this.designationObject[changeTo],
          value: changeTo,
        };
        this.team = designationsDecoded["team"];
        this._shared.changeDesignationRedirect.next(changeTo);

        this._router.navigate(["/list-projects"]);
      } else {
        if (
          designationsToCheck.includes("sa") ||
          designationsToCheck.includes("user")
        ) {
          changeTo = designationsToCheck[0];
          this.designationSelected = {
            name: this.designationObject[changeTo],
            value: changeTo,
          };
          this._router.navigate(["/help"]);
        } else {
          this.designationSelected = {
            name: this.designationObject[currentDesignation],
            value: currentDesignation,
          };

          this._router.navigate(["/list-projects"]);
        }
      }
    }
    return;
  }
}
